import React from 'react'
import Helmet from 'react-helmet'

export default function PageLayout({
  title,
  pageSummary,
  pageClassName,
  children,
}) {
  return (
    <div className="main__outer">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="splash">{pageSummary}</div>
      <div className="main">
        <div className={pageClassName}>{children}</div>
      </div>
    </div>
  )
}
