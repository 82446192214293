import React from 'react'
import { injectIntl, IntlProvider } from 'react-intl'
import { messagesFromNodes } from '../utils'

/**
 * Merges previous and current messages
 * See: https://github.com/yahoo/react-intl/issues/1109
 */
const NestedIntlProviderBase = ({ intl, ...props }) => {
  const { messages, ...otherProps } = props
  const combinedMessages = {
    ...intl.messages,
    ...messages,
  }
  return <IntlProvider messages={combinedMessages} {...otherProps} />
}

export const NestedIntlProvider = injectIntl(NestedIntlProviderBase)

/**
 * HOC that wraps component with NestedIntlProvider with messages from
 * page query.
 *
 * returns {React.Component}
 */
const injectNestedIntlProvider = WrappedComponent => {
  const WrapComponent = props => {
    if (!props.data || !props.data.messages) {
      console.log(props)
      throw new Error('Error: data.messages prop is missing.')
    }
    return (
      <NestedIntlProvider
        messages={messagesFromNodes(props.data.messages.edges)}
      >
        <WrappedComponent {...props} />
      </NestedIntlProvider>
    )
  }
  return WrapComponent
}

export default injectNestedIntlProvider
